import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store/configureStore'
import { srpQueryObject } from '@/components/oldComponents/Dropdown/HomeSearchContainer';

export interface FlightSearchQueryObj {
    query: srpQueryObject
}


const initialState: FlightSearchQueryObj = {
    query : {
        adults: "1",
        childs: "0",
        infants: "0",
        class: 'Economy',
        depart_date: "",
        from: "",
        to: "",
        intl: 'n',
        origin: "",
        destination: "",
        sft: "",
        sd: 0,
        rnd_one: "O",
        isCfw: false,
        sourceCountry: "",
        destinationCountry: ""
    }
        
}

export const QuerySlice = createSlice({
    name: 'query',
    initialState,
    reducers: {
        setQueryRedux: (state, action: PayloadAction<any>) => {
            state.query = action.payload
        },
    }
})

export const { setQueryRedux } = QuerySlice.actions
export const getQueryRedux = (state: RootState): Array<any> => state.query
const QueryReducer = QuerySlice.reducer
export default QueryReducer
