import { combineReducers } from '@reduxjs/toolkit'
import ResultsReducer from '../slices/results'
import SheetsReducer from '../slices/sidesheetSlice'
import ModalsReducer from '../slices/modalSlice'
import DropDownReducer from '../slices/dropdown'
import ModifySearch from '../slices/modifySearch'
import AbConfigReducer from '../slices/abConfig'
import NavTabsReducer from '../slices/navTabState'
import WalletReducer from '../slices/wallet'
import DetailsReducer from '../slices/details'
import LineageId from '../slices/lineageId'
import MerchResultsReducer from '../slices/merchResults'
import TopFiltersSlice from '../slices/topFiltersState'
import ItineraryReducer from '../slices/itinerary'
import DialogsReducer from '../slices/dialogs'
import FormsSlice from '../slices/forms'
import ToastSlice from '../slices/toastSlice'
import SrpSlice from '../slices/srpSlice'
import QuerySlice from '../slices/query'

const createReducer = (injectedReducers = {}) =>
    combineReducers({
        results: ResultsReducer,
        topFilters: TopFiltersSlice,
        modals: ModalsReducer,
        sidesheets: SheetsReducer,
        dropdown: DropDownReducer,
        modifySearch: ModifySearch,
        abConfig: AbConfigReducer,
        wallet: WalletReducer,
        navtabsState: NavTabsReducer,
        details: DetailsReducer,
        lineageId: LineageId,
        merchResults: MerchResultsReducer,
        itinerary: ItineraryReducer,
        dialogs: DialogsReducer,
        forms: FormsSlice,
        toasts: ToastSlice,
        srpSeachData: SrpSlice,
        query: QuerySlice,
        ...injectedReducers
    })

export default createReducer()
